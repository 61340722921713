import React from "react"
import { graphql } from "gatsby"
import { Accordion } from "../components/accordion"
import { Hero } from "../components/hero"
import { HomepageCta } from "../components/homepage-cta"
import { InlineMediaCard } from "../components/media-card/InlineMediaCard"
import { Paper } from "../components/paper"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useAuth0 } from "@auth0/auth0-react"

const Homepage = ({ data }) => {
  const { faqs } = data.homepage
  const { allSanityPositionStatements } = data
  const eventsToMap = data.allEvents.edges
  const { isAuthenticated } = useAuth0()

  return (
    <>
      <Seo title="Welcome" />
      <Layout>
        <Hero
          title="Musculoskeletal Futures"
          subTitle="The BSSR is dedicated to the improvement of musculoskeletal radiology in the United Kingdom."
        />

        <HomepageCta />

        <section className="bg-gray-100 mt-8 pt-4 pb-10">
          <div className="max-w-6xl mx-auto grid lg:grid-cols-2 lg:gap-x-8 gap-y-8">
            <div className="overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="serif text-xl text-gray-700 md:text-3xl font-bold mt-4 mb-6 ">
                  BSSR Events
                </h3>

                {eventsToMap.length > 0 ? (
                  <ul className="grid gap-y-4">
                    {eventsToMap.map((event) => {
                      return (
                        <li key={event.node.id}>
                          <InlineMediaCard
                            to={`/events/${event.node.slug.current}`}
                            title={event.node.title}
                            meta={event.node.eventStart}
                            subTitle={event.node.venue}
                            featureImage={event.node.featureImage}
                          />
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  <p>There are no upcoming events</p>
                )}
              </div>
            </div>

            <div className="overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="serif text-xl text-gray-700 md:text-3xl font-bold mt-4 mb-6 ">
                  Positions & Policies
                </h3>

                {console.log("all sanity", allSanityPositionStatements)}

                {allSanityPositionStatements &&
                allSanityPositionStatements.edges.length > 0 ? (
                  <ul className="grid gap-y-4">
                    {allSanityPositionStatements.edges.map((policy) => {
                      return (
                        <li key={policy.node.id}>
                          <Paper
                            to={`/position-and-policy/${policy.node.slug.current}`}
                            date={policy.node.publishedAt}
                            title={policy.node.title}
                          />
                        </li>
                      )
                    })}
                  </ul>
                ) : (
                  <p>There are no published position statements or policies.</p>
                )}
              </div>
            </div>
          </div>
        </section>
        {/* {videos && videos.edges.length > 0 && (
          <section id="latestVideos" className="py-16">
            <div className="max-w-6xl mx-auto px-3">
              <ul className="grid lg:grid-cols-3 gap-4">
                {videos.edges.map((video) => {
                  const { node } = video

                  return (
                    <li key={node.id}>
                      <MediaCard
                        title={node.name}
                        subTitle={node.author}
                        isPrivate={node.restricted}
                        to={`/training-and-education/videos/${node.slug.current}`}
                      />
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
        )} */}

        <section
          id="faqs"
          className={`bg-gray-100 ${!isAuthenticated && "mt-12"}`}
        >
          <div className="max-w-6xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto divide-y-2 divide-gray-200">
              <h2 className="serif text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
                Frequently asked questions
              </h2>

              <Accordion items={faqs} />
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Homepage

export const query = graphql`
  query {
    homepage: sanityHomepage {
      faqs {
        title
        _rawContent
      }
      events {
        id
        title
        eventStart(formatString: "MMM Do YYYY")
        venue
        slug {
          current
        }
      }
    }

    allSanityPositionStatements: allSanityPositionStatement(
      limit: 3
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM YYYY")
          slug {
            current
          }
        }
      }
    }

    allEvents: allSanityEvent(
      limit: 3
      sort: { fields: eventStart, order: ASC }
      filter: { isPastEvent: { eq: false } }
    ) {
      edges {
        node {
          id
          title
          featureImage {
            asset {
              id
            }
          }
          eventStart(formatString: "MMM Do YYYY")
          venue
          slug {
            current
          }
        }
      }
    }

    videos: allSanityVideo(limit: 3) {
      edges {
        node {
          id
          name
          author
          restricted
          slug {
            current
          }
        }
      }
    }
  }
`
