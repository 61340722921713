import React from "react"
import { Button } from "../button"
import { useAuth0 } from "@auth0/auth0-react"

export const HomepageCta = () => {
  const { isAuthenticated } = useAuth0()

  return (
    <section id="cta" className="mt-10 md:mt-16 px-3">
      <div className="md:text-center serif max-w-6xl mx-auto">
        <h2 className="font-sans text-base text-indigo-800 font-semibold tracking-wide uppercase">
          Who we are
        </h2>
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl md:leading-loose">
          Leading Opinion in Musculoskeletal Radiology
        </p>
        <p className="mt-8 max-w-2xl text-xl text-gray-500 md:mx-auto md:text-2xl md:leading-9">
          The British Society of Skeletal Radiologists is the Special Interest
          Group of the Royal College of Radiologists representing
          Musculoskeletal Radiologists.
        </p>
        <div className="py-12 bg-white hidden md:block">
          <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-6xl lg:px-8">
            <h2 className="sr-only">A better way to send money.</h2>
            <dl className="text-base lg:text-lg space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <div className="mt-5">
                  <dt className="serif text-lg md:text-xl lg:text-2xl leading-6 font-medium text-gray-900">
                    Key opinion Leaders{" "}
                  </dt>
                  <dd className="mt-2 text-gray-500">
                    Our experts are regularly drawn upon to advise the NHS, NICE
                    and the Royal College of Radiologists. We publish position
                    statements and guidelines.
                  </dd>
                </div>
              </div>

              <div>
                <div className="mt-5">
                  <dt className="serif text-lg md:text-xl lg:text-2xl leading-6 font-medium text-gray-900">
                    Education
                  </dt>
                  <dd className="mt-2 text-gray-500">
                    We run several annual courses and meetings and provide
                    online educational content. Educational development is
                    fundamental to our purpose.
                  </dd>
                </div>
              </div>

              <div>
                <div className="mt-5">
                  <dt className="serif text-lg md:text-xl lg:text-2xl leading-6 font-medium text-gray-900">
                    Success Network
                  </dt>
                  <dd className="mt-2 text-gray-500">
                    Friendships, fellowships and future careers start with the
                    BSSR. MSK Radiologists at all career stages can readily
                    network together.
                  </dd>
                </div>
              </div>
            </dl>
          </div>
        </div>

        {!isAuthenticated && (
          <div className="hidden md:block font-sans text-indigo-800 font-bold text-xl md:text-2xl py-6">
            <span className="font-sans text-base text-indigo-800 font-semibold tracking-wide uppercase">
              Ready to start your membership?
            </span>

            <div className="mt-8">
              <Button href="/register">Join the BSSR today</Button>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
