/**
 * A paper like card that is used to link users through to
 * a content page. Perfect example for this component is on the
 * training-and-education page, see the Fellowships section
 */

import React from "react"
import { Link } from "gatsby"

export const Paper = ({ date, title, meta, to }) => {
  return (
    <Link
      to={to}
      className="inline-block p-4 bg-white rounded-sm border-b-2 transition hover:border-indigo-900 shadow hover:shadow-lg w-full"
    >
      <span className="block mb-2 text-gray-500 uppercase font-bold text-xs">
        {date}
      </span>

      <h3 className="serif font-bold text-sm lg:text-lg text-indigo-900">
        {title}
      </h3>

      {meta && (
        <span className="block text-xs lg:text-sm mt-3 text-gray-700">
          {meta}
        </span>
      )}
    </Link>
  )
}
