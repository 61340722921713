import React, { useEffect, useState } from "react"
import classnames from "classnames"
import PortableText from "../portable-text"

// Move to separate file
export const AccordionItem = ({ content, title, isActive }) => {
  const [active, setIsActive] = useState(false)

  /**
   * If the isActive prop is true then force the state to set `active`
   * to true. This means the item will be forced open on page load.
   */
  useEffect(() => {
    if (isActive) {
      setIsActive(isActive)
    }
  }, [isActive])

  const toggleState = () => setIsActive(!active)

  return (
    <div className="pt-6">
      <dt className="text-lg">
        <button
          className="text-left w-full flex justify-between items-start text-gray-400"
          aria-label="Click to expand or collapse item"
          onClick={toggleState}
        >
          <span className="font-medium text-gray-900">{title}</span>
          <span className="ml-6 h-7 flex items-center">
            <svg
              className={classnames("rotate-0 h-6 w-6 transform", {
                "-rotate-180": active,
              })}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </button>
      </dt>
      <dd
        className={classnames("mt-2 pr-12 text-base text-gray-500", {
          block: active,
          hidden: !active,
        })}
      >
        <PortableText blocks={content} />
      </dd>
    </div>
  )
}
