import React from "react"

export const Hero = ({ imgSrc, title, subTitle }) => {
  return (
    <section
      id="heroFeaturedEvent"
      className="z-0 overflow-hidden sm:aspect-w-16 sm:aspect-h-7 md:aspect-h-5 xl:aspect-h-4"
    >
      <img
        className="hidden sm:block object-cover"
        src="https://res.cloudinary.com/radiology/image/upload/v1610496141/_5120001_1.jpg"
        alt=""
      />

      <div className="container mx-auto h-full sm:flex items-center">
        <div className="max-w-2xl bg-indigo-900 text-white lg:shadow-xl w-full p-6 md:p-8">
          <h1 className="serif font-extrabold text-2xl md:text-3xl lg:text-5xl">
            {title}
          </h1>
          {subTitle && (
            <p className="prose-lg lg:prose-xl text-white my-5 mx-1">
              {subTitle}
            </p>
          )}
        </div>
      </div>
    </section>
  )
}
