import React from "react"
import { AccordionItem } from "./AccordionItem"

export const Accordion = ({ items }) => {
  return (
    <dl className="mt-6 space-y-6 divide-y divide-gray-200">
      {items.map((item, index) => {
        return (
          <AccordionItem
            title={item.title}
            content={item._rawContent}
            key={index}
            isActive={index === 0}
          />
        )
      })}
    </dl>
  )
}
